<template>
  <div class="row">
    <div class="col">
      <div class="card card-custom">
        <!-- begin::Loading -->
        <div v-if="isMounted === false || isLoading" class="loading-container loading-overlap">
          <div class="loading-backdrop rounded" />
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span class="float-left">Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
        <!-- end::Loading -->

        <!-- begin::Content -->
        <div class="card-body min-h-100px">
          <!-- begin::Error alert -->
          <template v-if="isErrorActive">
            <error-translation-alert
              ref="errorAlert"
              :error="activeError"
              class="shadow"
            />
          </template>
          <!-- end::Error alert -->

          <!-- begin::Notification types -->
          <div v-else class="row">
            <div v-for="(notificationType, idx) in notifications.types" :key="`notification.type.${idx}.${notificationType}`" class="col-12 my-3">
              <label class="option cursor-pointer h-100">
                <span class="option-label my-auto">
                  <span class="option-head">
                    <span class="option-title">{{ $tu(`notifications.${notificationType}.title`) || notificationType }}</span>
                  </span>
                  <span v-if="$te(`notifications.${notificationType}.description`)" class="option-body my-auto">{{ $t(`notifications.${notificationType}.description`) }}</span>
                </span>

                <span class="option-control w-auto my-auto">
                  <span :class="['switch', { 'switch-success': notificationPreferences && notificationPreferences.isNotificationPreferenceEnabled(notificationType) }]">
                    <label>
                      <input
                        type="checkbox"
                        :checked="notificationPreferences && notificationPreferences.isNotificationPreferenceEnabled(notificationType)"
                        @change.prevent="toggleNotificationPreference(notificationType, $event.target.checked)"
                        name="select"
                      >
                      <span />
                    </label>
                  </span>
                </span>
              </label>
            </div>
          </div>
          <!-- end::Notification types -->
        </div>
        <!-- end::Content -->
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPreferences from '@/libs/classes/notification_preferences';
import { errorComponentMixin } from '@vedicium/core-vue';
import { company as companyLib } from '@vedicium/core-vue/build/modules/company';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  props: {
    notifications: {
      type: Object,
      default: () => ({
        types: [],
        methods: [],
      }),
    },
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      notificationPreferences: null,
    };
  },

  async mounted () {
    this.$set(this, 'isLoading', true);

    try {
      this.$set(this, 'notificationPreferences', await NotificationPreferences.getForCompany(companyLib.current._meta.guid));
    } catch (e) {
      // Don't handle this error, because we should just do as if nothing happened
      // All notifications will show that they're not activated
      // this.$errors.handle(e, { component: this, ui_element: false });
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {
    async toggleNotificationPreference (type = null, enabled = null) {
      if (!type || this.notifications.types.includes(type) === false || typeof enabled !== 'boolean') {
        return;
      }

      const notificationPreferences = (this.notificationPreferences ? this.notificationPreferences.clone() : new NotificationPreferences());
      notificationPreferences.setNotificationPreference(type, this.notifications.methods, enabled);

      // Set notification preference
      this.$set(this, 'isLoading', true);
      try {
        const response = await this.$ws.put(`${NotificationPreferences.uri}/companies/${companyLib.current._meta.guid}`, {
          body: {
            notification_preferences: notificationPreferences.notification_preferences,
          },
        });

        this.$set(this, 'notificationPreferences', new NotificationPreferences().merge(response));
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
  },
};
</script>
